@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap');

body {
   font-family: 'Roboto Condensed';
   font-size: 2em;
   color: white;
   background-color: #0e0a19;
   text-align: center;
}

a {
    font-weight: 700;
    text-decoration: none;
    color: #639cad;

    &:hover {
        color: #b8e6f4;
    }

    &:active {
        color: white;
    }
}

.spyshit-textlink-disabled {
    font-weight: 700;
    color: #38535b;
    cursor: default;
}

.spyshit-text {
    margin-bottom: 10px
}

img {
    max-width: 100%;
}